import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import TechInfo from '../TechInfo';
import namedropMockup2 from '../../assets/images/namedrop-mockup2.png';
import namedropMockup3 from '../../assets/images/namedrop-mockup3.png';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import styles from './FeaturesIncludedSection.module.scss';

const messages = defineMessages({
  title: {
    id: 'featuresIncludedSection.title',
    defaultMessage: 'features included'
  },
  jobFeed: {
    id: 'featuresIncludedSection.jobFeed',
    defaultMessage: 'Job feed'
  },
  jobManagement: {
    id: 'featuresIncludedSection.jobManagement',
    defaultMessage: 'Job management'
  },
  inAppMessaging: {
    id: 'featuresIncludedSection.inAppMessaging',
    defaultMessage: 'In-app messaging'
  },
  friendList: {
    id: 'featuresIncludedSection.friendList',
    defaultMessage: 'Friend list'
  },
  socialConnection: {
    id: 'featuresIncludedSection.socialConnection',
    defaultMessage: 'Social connection'
  },
  multipleLoginOption: {
    id: 'featuresIncludedSection.multipleLoginOption',
    defaultMessage: 'Multiple login option'
  }
});

function FeaturesIncludedSection() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FormattedMessage {...messages.title} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.image}>
          <img src={namedropMockup2} alt="" />
        </div>
        <div className={styles.content}>
          <div>
            <TechInfo title={messages.jobFeed} />
          </div>
          <div>
            <TechInfo title={messages.jobManagement} />
          </div>
          <div>
            <TechInfo title={messages.inAppMessaging} />
          </div>
          <div>
            <TechInfo title={messages.friendList} />
          </div>
          <div>
            <TechInfo title={messages.socialConnection} />
          </div>
          <div>
            <TechInfo title={messages.multipleLoginOption} />
          </div>
        </div>
        <div className={styles.image}>
          <img src={namedropMockup3} alt="" />
        </div>
      </div>
      <div className={styles.greenIcon}>
        <GreenSquareIcon />
      </div>
    </div>
  );
}

export default FeaturesIncludedSection;
