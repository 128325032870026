import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../../App';
import Hero from '../../components/Hero';
import ContactSection from '../../components/ContactSection';
import TechInfo from '../../components/TechInfo';
import FeaturesIncludedSection from '../../components/FeaturesIncludedSection';
import SmallQuotationSection from '../../components/QuotationSection/SmallQuotationSection';
import MiniQuotationSection from '../../components/QuotationSection/MiniQuotationSection';
import Container from '../../components/Container';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import NamedropPageHeroImage from '../../gatsbyImages/NamedropPageHeroImage';
import NamedropMockupImage from '../../gatsbyImages/NamedropMockupImage';
import SecondQuoteBackgroundImage from '../../gatsbyImages/SecondQuoteBackgroundImage';
import josephTerpstra from '../../assets/images/joseph-terpstra-avatar.png';
import styles from './projectPages.module.scss';

const messages = defineMessages({
  title: {
    id: 'namedrop.title',
    defaultMessage: 'Namedrop'
  },
  description: {
    id: 'namedrop.description',
    defaultMessage:
      'Location: Washington, DC {br} Project Length: 3 months {br}'
  },
  contactSectionTitle: {
    id: 'namedrop.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'namedrop.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  },
  techUsed: {
    id: 'namedrop.techUsed',
    defaultMessage: 'Tech used: '
  },
  react: {
    id: 'namedrop.react',
    defaultMessage: 'React'
  },
  java: {
    id: 'namedrop.java',
    defaultMessage: 'Java'
  },
  reactNative: {
    id: 'namedrop.reactNative',
    defaultMessage: 'React Native'
  },
  aws: {
    id: 'namedrop.aws',
    defaultMessage: 'AWS'
  },
  firstSectionTitle: {
    id: 'namedrop.firstSectionTitle',
    defaultMessage:
      'How Namedrop got a validated software platform 4 weeks after initial user research interviews'
  },
  firstSectionDescription1: {
    id: 'namedrop.firstSectionDescription1',
    defaultMessage: `Namedrop distrupts the status quo on working for friends and family for free. Whether it comes to airport drop-offs or loaning the yard you can even choose from your friends' trusted source of favors.`
  },
  firstSectionDescription2: {
    id: 'namedrop.firstSectionDescription2',
    defaultMessage: `Namedrop is a gig app with added social connections, easy in app messaging and a smooth UX/UI for adding and applying to gigs/small jobs needed by your friends and neighbors. The concept was infused by the idea of Fiverr and Facebook's call on local communities, focusing on trust and the quality of human relationships. To follow through this vision we've used Design Thinking as part of our delivery process.`
  },
  firstQuoteText: {
    id: 'namedrop.firstQuoteText',
    defaultMessage: `Adam Jermann and the entire team at StreamBright has exceeded every expectation I had when it came to their software development, quick response times and overall professionalism. Simply put, I strongly believe that any executive/startup founder looking for a development team that brings the most value, Streambright is THE answer, especially since I personally struggled and lost a lot of money with previous offshore teams.`
  },
  quotationDescription1: {
    id: 'namedrop.quotationDescription1',
    defaultMessage: `If you want to provide the best service in town, you need to know your customers well, but if you don't know anyone yet how can you be sure anyone will engage with your vision? You have to engage with them first to understand better how people are willing to work in their free time and what kind of gigs are best selling on the market? But who has so much free time and willing to do random side gigs? `
  },
  quotationDescription2: {
    id: 'namedrop.quotationDescription2',
    defaultMessage: `Initial research at local universities helped us a lot to simply create and prioritize the list of features and ultimately to map the flows within the app. In the meantime we were also creating the wireframes of the screens future users would be facing. It took a few days or maybe weeks, but eventually we had a clear development scope, a roadmap and go to market strategy.`
  },
  miniQuotationDescription1: {
    id: 'namedrop.miniQuotationDescription1',
    defaultMessage:
      ' “The most important goal during this phase was to plan and build an MVP with the highest priority features fast, to get feedback on the user experience and feature priorities ASAP.”'
  },
  secondSectionDescription: {
    id: 'namedrop.secondSectionDescription',
    defaultMessage:
      "Our weapon of choice was React, Java, AWS and Expo this time. We've used AWS and Expo to host our apps, React and React Native for the frontend, Java for the backend. Following the rapid prototyping method, using a small instance and leveraging the features of Expo enabled us to release the MVP for both iOS and Android at the same time, just a few weeks after we've visited those universities. Now that Namedrop owns a custom software platform, the founder is able to focus resources on strategic growth efforts."
  },
  miniQuotationDescription2: {
    id: 'namedrop.miniQuotationDescription2',
    defaultMessage:
      'On track to meet their goal of scaling in the next three years, Namedrop has realized a business transformation that will alter the course of influencer marketing. Not only do they own the gig economy software of its kind in the industry, the spinoff platform called Tenfluencer is now positioned to connect brands with nano influencers so they can advertise in the most effective in a way.'
  }
});

function Namedrop() {
  return (
    <App>
      <Helmet>
        <title>Streambright - Case Study: NameDrop</title>
        <meta
          name="description"
          content="How Namedrop got a validated software platform 4 weeks after initial user research."
        />
      </Helmet>
      <Hero
        icon={<GreenSquareIcon />}
        title={<FormattedMessage {...messages.title} />}
        description={
          <>
            <FormattedMessage
              {...messages.description}
              values={{ br: <br /> }}
            />
            <div className={styles.textContainer}>
              <FormattedMessage {...messages.techUsed} />
              <TechInfo title={messages.react} />
              <TechInfo title={messages.java} />
              <TechInfo title={messages.aws} />
              <TechInfo title={messages.reactNative} />
            </div>
          </>
        }
        backgroundImage={NamedropPageHeroImage}
        image={NamedropMockupImage}
      />
      <Container>
        <div className={styles.title}>
          <FormattedMessage {...messages.firstSectionTitle} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.firstSectionDescription2} />
        </div>
        <div className={styles.quotationContainer}>
          <SmallQuotationSection
            backgroundImage={SecondQuoteBackgroundImage}
            description={messages.firstQuoteText}
            avatar={<img src={josephTerpstra} alt="Joseph Terpstra" />}
            authorName="Joseph Terpstra"
            jobTitle="Founder and CEO"
            reverse
          />
          <div className={styles.blueIcon}>
            <GreenSquareIcon />
          </div>
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.quotationDescription1} />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.quotationDescription2} />
        </div>
        <FeaturesIncludedSection />
        <div className={styles.description}>
          <FormattedMessage {...messages.secondSectionDescription} />
        </div>
        <MiniQuotationSection
          description={messages.miniQuotationDescription2}
        />
      </Container>
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default Namedrop;
